import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@mui/material";
import TypingMessage from "../../untils/TypingMessage";
import "./Chat.scss";
import { useTranslation } from 'react-i18next';
import { zipImages } from '../../untils/until';
import config from '../../config'

const Chat = (props: any) => {
  const [messages, setMessages] = useState<any>([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const messageArr = useRef<any>([]);
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const hasDone = useRef(true);
  const sceneSource:any = {0:{npcImg:'https://oss.tnworld.cn/kunlun/assets/xiu/xiuxiu.png',source:'XIU'},1:{npcImg:'https://oss.tnworld.cn/kunlun/assets/nuo/nuo.png',source:'NUO'}}
  const [imgName, setImgName] = useState<any>(props.dialogData.imgName);
  const scrollToBottom = () => {
    var bottomElement: any = document.getElementById("chat-box");
    bottomElement.scrollTop = bottomElement.scrollHeight;
  };

  useEffect(() => {
    // 默认显示欢迎语 判断是不是第一次打开
    const str = t('scene_'+props.sceneId+'_npc_desc').split('\n')[0];
    const str1 = t('scene_'+props.sceneId+'_npc_desc').split('\n')[1];
    const str2 = t('scene_'+props.sceneId+'_npc_desc').split('\n')[2];
    if (sessionStorage.getItem("has-open-chat")) {
      let temp: any = [{
        text: str,
        sender: 'bot',
        typing: 1
      }, {
        text: str1,
        sender: 'bot',
        typing: 1
      }, {
        text: str2,
        sender: 'bot',
        typing: 1
      }];
      setMessages(temp);
    } else {
      let obj = {};
      obj = {
        text: str,
        sender: 'bot',
        typing: 2
      }
      let temp: any = [];
      temp[0] = obj;
      setMessages(temp);
      setTimeout(() => {
        obj = {
          text: str1,
          sender: 'bot',
          typing: 2
        }
        temp.push(obj)
        setMessages(temp);

        setTimeout(() => {
          obj = {
            text: str2,
            sender: 'bot',
            typing: 2
          }
          temp.push(obj)
          setMessages(temp);
          sessionStorage.setItem("has-open-chat", 'true');
        }, 2100)
      }, 3200)
    }
  }, [])

  // 关闭弹窗 
  const closeModal = () => {
    window.eventBus.emit('closeNpcChat');
  };

  const handleTypingComplete = (index: any) => {
    if (!hasDone.current) return;
    setIsTyping(false)
    console.log('?????done')
    setMessages((prevMessages: any) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[index].typing = 1;
      return updatedMessages;
    });
  };

  const handleSend = async () => {
    if (isTyping) return;
    if (input.trim()) {
      messageArr.current = [...messages, { text: input, sender: "user", typing: 1 }, { typing: 3, text: 1, sender: 'bot' }]
      setMessages((prevMessages: any) => [
        ...prevMessages,
        { text: input, sender: "user", typing: 1 },
        { typing: 3, text: 1, sender: 'bot' }
      ]);
      setTimeout(() => {
        scrollToBottom()
      }, 100)
      setInput("");
      setIsTyping(true);
      const lan = localStorage.getItem("language") === 'EN' ? 'en' : 'cn'
      const host = config.host
      let eventSource: any = new EventSource(`${host}qwenStream?userMessage=` + input + '&lan=' + lan + '&source='+sceneSource[props.npcId].source);
      let messageStr = "";
      const length = messageArr.current.length - 1;
      eventSource.onmessage = function (event: any) {
        hasDone.current = false;
        const data = JSON.parse(event.data);
        const text = data.output.choices[0].message.content
        messageStr += text;

        console.log(text)

        const temp = JSON.parse(JSON.stringify(messageArr.current));
        let obj = {};
        obj = {
          text: messageStr,
          sender: 'bot',
          typing: 2
        }
        temp[length] = obj;
        // 逐字输入 设定一个定时滚动到最底部 在输出后在滚动一次 并清除滚动定时器
        if (data.output.choices[0].finish_reason === 'stop') {
          hasDone.current = true;
          eventSource.close();
        }
        setMessages(temp);
      };
    }
  };

  return (
    <div className="chat-dialog" onClick={closeModal}>
      <div className="close-box" onClick={closeModal}>
        <img src="/assets/images/close-icon.png" alt="" />
      </div>
      <div className="virtual" style={{ width: "40vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={zipImages(sceneSource[props.npcId].npcImg)} alt="" />
      </div>
      <div className={`chat-container ${imgName}` } onClick={(e) => e.stopPropagation()}>
        <div className="messages" id="chat-box">
          {messages.map((message: any, index: any) => (
            <div key={index} className={`message ${message.sender}`}>
              <Paper className="message-text">
                {message.typing == 2 ?
                  <TypingMessage message={message.text} answering={() => setIsTyping(true)} onComplete={() => handleTypingComplete(index)} isTyping={isTyping} /> : message.typing == 1 ? message.text :
                    <>
                      <span className="dot">.</span>
                      <span className="dot">.</span>
                      <span className="dot">.</span>
                    </>
                }
              </Paper>
            </div>
          ))}
        </div>
        <div className="input-container">
          <input
            ref={inputRef}
            autoFocus={true}
            value={input}
            onFocus={scrollToBottom}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && handleSend();
            }}
            placeholder={isTyping ? "正在输入..." : "请输入..."}
            autoComplete="off"
            disabled={isTyping}
          />
          <img src="/assets/images/arrow-back.png" onClick={handleSend} className="arrow-back" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Chat;
