// Carousel.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './carousel.scss';
import { useTranslation } from 'react-i18next';

interface CarouselItem {
    id: number;
    pos: number;
    src: string
}const Carousel: React.FC = () => {
    const { i18n } = useTranslation();
    const [items, setItems] = useState<CarouselItem[]>([
        { id: 1, pos: -2, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu3.webp' },
        { id: 2, pos: -1, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu2.webp' },
        { id: 3, pos: 0, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu1.webp' },
        { id: 4, pos: 1, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu5.webp' },
        { id: 5, pos: 2, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu4.webp' },
    ]);
    const calArr = useRef<any>(items);//存储实时改变的数组
    const navigate = useNavigate();
    const currentScene = useRef('qiangxiu');//场景名字
    const newImages1 = [
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu1.webp',
            text: null
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo0.webp',
            text: null
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/yunjin/yunjin1.webp',
            text: "coming soon"
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/xingshi/xingshi1.webp',
            text: "coming soon"
        },
    ];
    const timer = useRef<any>(null);

    const changeSwiper = (index: any) => {
        switch (index) {
            case 0:
                currentScene.current = 'qiangxiu';
                calArr.current = [
                    { id: 1, pos: -2, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu3.webp' },
                    { id: 2, pos: -1, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu2.webp' },
                    { id: 3, pos: 0, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu1.webp' },
                    { id: 4, pos: 1, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu5.webp' },
                    { id: 5, pos: 2, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu4.webp' },
                ];
                setItems(calArr.current);
                break;
            case 1:
                currentScene.current = 'nuo';
                calArr.current = [
                    { id: 1, pos: -2, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo3.webp' },
                    { id: 2, pos: -1, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo2.webp' },
                    { id: 3, pos: 0, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo1.webp' },
                    { id: 4, pos: 1, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo5.webp' },
                    { id: 5, pos: 2, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo4.webp' },
                ];
                setItems(calArr.current);
                break;
            default:
                break;
        }
    }

    const update = (newActiveId: number, pos?: number) => {
        const newActivePos = pos || calArr.current.find((item: any) => item.id === newActiveId)?.pos;
        if (newActivePos === undefined) return;

        const updatedItems = calArr.current.map((item: any) => ({
            ...item,
            pos: getPos(item.pos, newActivePos),
        }));
        calArr.current = updatedItems;
        setItems(updatedItems);
    };

    // 点击切换
    const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        resetInterval();
        const newActive = event.currentTarget;
        const newActiveId = parseInt(newActive.getAttribute('data-id')!, 10);
        const activeItem = items.find(item => item.id === newActiveId);

        if (!activeItem || activeItem.pos === 0) {
            return;
        }

        update(newActiveId);
    };

    // 跳转
    const goAnyWhere = () => {
        navigate('/scene?scene='+currentScene.current);
    }
    const getPos = (current: number, active: number): number => {
        const diff = current - active;

        if (Math.abs(diff) > 2) {
            return -current;
        }

        return diff;
    };

    const resetInterval = () => {
        clearInterval(timer.current);
        timer.current = null;

        timer.current = setInterval(() => {
            update(0, -1);
        }, 3000);
    };

    const hanleMove = (pos: any, type: string) => {
        if (pos !== 0) return;
        if (type === "moveIn") {
            clearInterval(timer.current);
            timer.current = null;
        } else {
            resetInterval()
        }
    }

    useEffect(() => {
        timer.current = setInterval(() => {
            update(0, -1);
        }, 3000);

        return () => {
            clearInterval(timer.current);
            timer.current = null;
        }
    }, [])

    return (
        <div
            className="carousel"
        >
            <div className='part-2-title'>
                {i18n.t('home_kunlun_metaverse')}
            </div>
            <div className='part-2-title2'>
                {i18n.t('home_qiang_title')}
            </div>
            <ul className="carousel__list">
                {items.map(item => (
                    <li
                        key={item.id}
                        className={`carousel__item ${item.pos === 0 ? 'carousel__item_active' : ''}`}
                        data-pos={item.pos}
                        data-id={item.id}
                        onClick={handleClick}
                        id={"pos" + item.pos}
                        onMouseEnter={() => hanleMove(item.pos, 'moveIn')}
                        onMouseLeave={() => hanleMove(item.pos, "moveOut")}
                    >
                        <img src={item.src} />
                    </li>
                ))}
            </ul>
            <div className='part-2-des'>
                {i18n.t('home_qiang_des')}
            </div>
            <div className='visit-btn' onClick={goAnyWhere}>
                {i18n.t('home_visit_metaverse')}
            </div>
            <div className='row-imgs'>
                {newImages1.map((item: any, index: any) => (
                    <div onClick={() => changeSwiper(index)}>
                        <img src={item.src} />
                        {item.text && <div className='comming'><div>{item.text}</div></div>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
