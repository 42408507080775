import React, { useState, useEffect } from "react";
import './MessageDialog.scss';
import { useTranslation } from 'react-i18next';
import { zipImages } from "../../untils/until";
const MessageDialog = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const { i18n } = useTranslation();
  const [theme,setTheme] = useState<any>(props.dialogData.theme); 
  const [imgName, setImgName] = useState<any>(props.dialogData.imgName);
  const [showPoster,setShowPoster] = useState(false);

  const showHost = "https://oss.tnworld.cn"
  const downloadHost = "https://tnworld.oss-cn-shanghai.aliyuncs.com"
  const posterList = [
    '/kunlun/assets/xiu/poster1.png',
    '/kunlun/assets/xiu/poster2.png',
    '/kunlun/assets/xiu/poster3.png',
    '/kunlun/assets/xiu/poster4.png',
    '/kunlun/assets/xiu/poster5.png',
  ]

  const [poster,setPoster] = useState(posterList[0]);
  // 关闭弹窗 为了显示动画效果 做延时关闭
  const closeModal = () => {
    setIsFadingOut(true);
    setIsModalOpen(false);
    setShowPoster(false);
    console.log(showPoster)
    if (showPoster) {
      window.eventBus.emit('closeDialog');
    } else {
      setTimeout(() => {
        window.eventBus.emit('closeDialog');
      }, 200)
    }

  };

  // 点击按钮 随机显示海报
  const handlePoster = (e:any,type:boolean) => {
    e.stopPropagation()
    const randomIndex = Math.floor(Math.random() * posterList.length);
    setPoster(posterList[randomIndex]);
    setShowPoster(type);
  };

  // 下载海报
  const downloadPoster = (e:any) => {
    e.stopPropagation()
    const link = document.createElement('a');
    // link.href = 'https://tnworld.oss-cn-shanghai.aliyuncs.com/kunlun/assets/xiu/xiu2.webp';
    link.href = downloadHost + poster;
    link.download = 'download_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <div className="message-dialog" onClick={closeModal}>
      {isModalOpen && <div className="close-box">
        <img src="/assets/images/close-icon.png" alt="" />
      </div>}
      {!showPoster &&<div className={`message-dialog-center ${isFadingOut ? 'fade-out' : ''}`} onClick={(e) => e.stopPropagation()}>
        <img src={`/assets/images/rectangle-${imgName}.png`} className="left-top-border" alt="" />
        <img src={`/assets/images/rectangle-${imgName}.png`} className="right-top-border" alt="" />
        <img src={`/assets/images/rectangle-${imgName}.png`} className="left-bottom-border" alt="" />
        <img src={`/assets/images/rectangle-${imgName}.png`} className="right-bottom-border" alt="" />
        <div className={`message-dialog-box ${theme==='light'?'message-dialog-box-light':'message-dialog-box-dark'}`}>
          {props.dialogData['diglog-'+props.dialogId].hasTitle &&<div className="message-title">
            {i18n.t(`scene_${props.sceneId}_title_${props.dialogId}`)}
          </div>}
          <img src={`/assets/images/divider-${imgName}.png`} className="divider-line" alt="" />
          {props.dialogData['diglog-'+props.dialogId].img && <img className="message-img" src={props.dialogData['diglog-'+props.dialogId].img}/>}
          <div className="message-text">
            {i18n.t(`scene_${props.sceneId}_point_${props.dialogId}`)}
          </div>
        </div>
      </div>}
      {
        showPoster && <div>
          <div className="poster-container" onClick={(e) => e.stopPropagation()} >
            <img src={zipImages(showHost + poster)} alt="" />
          </div>
        </div>
      }
      {isModalOpen && props.dialogData['diglog-'+props.dialogId].hasButton && <div className="message-dialog-button" onClick={(e)=>{showPoster?downloadPoster(e):handlePoster(e,true)}}>
        {showPoster?'收下祝福':'点击领取祝福盲盒'}
      </div>}
    { !showPoster &&<div className="close-tip">
      点击任意空白处关闭
    </div>}
    { showPoster &&<div className="share-tip">
      点击按钮，或移动端长按保存图片，将大美羌绣分享给更多的人
    </div>}
    </div>
  );
}

export default MessageDialog;