// src/TypingMessage.js

import React, { useState, useEffect, useRef } from 'react';

const TypingMessage = ({ message = '', onComplete = () => { }, isTyping = true,answering = () => {} }) => {
  const [displayedText, setDisplayedText] = useState('');
  const index = useRef(0);
  const textStr = useRef('');
  let bottomElement: any = document.getElementById("chat-box");
  const canScroll = useRef(true);
  const timer = useRef<any>(false);

  // 滚动到底部 逐字输出 需要在每次输出后调用一次
  const scrollToBottom = () => {
    bottomElement.scrollTop = bottomElement.scrollHeight;
  };

  bottomElement.addEventListener('wheel', (event:any) => {
    if (event.deltaY < 0) {
      canScroll.current = false
    }else if (event.deltaY > 50){
      canScroll.current = true
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      answering();
      if(message?.[index.current]){
        textStr.current += message?.[index.current] || "";
        setDisplayedText(textStr.current);
        if(canScroll.current){
          scrollToBottom()
        }
        if (index.current === message.length - 1) {
          console.log('???????typingover')
          clearInterval(interval);
          onComplete();
        }
        index.current += 1;
      }
    }, 35); // 每50毫秒显示一个字符

    return () => clearInterval(interval);
  }, [message, onComplete]);

  useEffect(() => {
    if (!isTyping && !timer.current) {
        timer.current = true;
        canScroll.current = true;
        textStr.current = "";
        index.current = 0;
        setTimeout(()=>{
          timer.current = false;
        },500)
    }
  }, [isTyping]);

  return <span>{displayedText}</span>;
};

export default TypingMessage;
